import React, { type FunctionComponent, useState } from 'react';
import { Text, useTranslation } from 'bb/i18n';
import {
    DeprecatedButton,
    CheckmarkIcon,
    CopyIcon,
    type ButtonVariant
} from 'bb/ui';

export type CopyButtonProps = {
    content: string;
    type?: ButtonVariant;
    copyKey?: string;
    bold?: boolean;
};

export const CopyButton: FunctionComponent<CopyButtonProps> = ({
    content,
    type = 'primary',
    copyKey,
    bold = false
}: CopyButtonProps) => {
    const { t } = useTranslation('myAccount');
    const [isCopied, setIsCopied] = useState(false);

    const copyCodeToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    };

    if (typeof navigator === 'undefined' || !navigator.clipboard) return null;

    return (
        <>
            <DeprecatedButton
                fluid
                variant={type}
                onClick={() => copyCodeToClipboard(content)}
                data-testid="copy-button"
                icon={<CopyIcon />}
                iconSize="small"
            >
                <Text as="span" t={copyKey || 'myAccount:copy'} bold={bold} />
            </DeprecatedButton>
            {isCopied && (
                <Text marginTop="extraSmall">
                    <CheckmarkIcon inline color="secondary-green" />
                    {t('myAccount:copied')}
                </Text>
            )}
        </>
    );
};
