import dynamic from 'next/dynamic';

export const ProfilePricesPerSubscription = dynamic(
    () =>
        import('./ProfilePricesPerSubscription').then(
            (mod) => mod.ProfilePricesPerSubscription
        ),
    { ssr: false }
);

export const ProfilePricesPerSubscriptionV2 = dynamic(
    () =>
        import('./ProfilePricesPerSubscriptionV2').then(
            (mod) => mod.ProfilePricesPerSubscriptionV2
        ),
    { ssr: false }
);
