import React, { type FunctionComponent } from 'react';
import { type ErrorResponse } from 'bb/api/browser/types';
import { useTranslation, Text } from 'bb/i18n';
import { Gap, Spinner } from 'bb/ui';
import { Button } from 'bb/ui/Button';

type LogoutAllDevicesProps = {
    logoutAllDevices: () => void;
    isLoading: boolean;
    error: ErrorResponse;
    isSuccess: boolean;
};

export const LogoutAllDevices: FunctionComponent<LogoutAllDevicesProps> = ({
    logoutAllDevices,
    isLoading,
    error,
    isSuccess
}) => {
    const { t } = useTranslation('myAccount');

    return (
        <Spinner ghost nested show={isLoading}>
            <Gap spacing={4}>
                <Text
                    as="h2"
                    variant="h5Secondary"
                    t="myAccount:logoutAllDevices"
                />
                <Text t="myAccount:logoutAllDevicesText" />

                {error && (
                    <Text
                        color="secondary-red"
                        t="myAccount:logoutAllDevicesError"
                    />
                )}
                {isSuccess && <Text t="myAccount:logoutAllDevicesSuccess" />}
                <Button
                    variant="outlined"
                    color="primary-black"
                    onClick={logoutAllDevices}
                    isLoading={isLoading}
                >
                    {t('myAccount:logoutAllDevices')}
                </Button>
            </Gap>
        </Spinner>
    );
};
