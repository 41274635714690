import React, { type FunctionComponent, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ToggleRow } from 'bb/common/ToggleRow';
import { SchemeForm } from 'bb/forms/SchemeForm';
import { useTranslation, Text, type TFunction } from 'bb/i18n';
import { Box, DeprecatedButton } from 'bb/ui';

const makeChangePasswordSchema = (t: TFunction<'userDetails'>) =>
    z.object({
        oldpassword: z.string().min(1, t('userDetails:oldPassword')),
        password: z
            .string()
            .min(6, t('userDetails:passwordValid'))
            .max(99, t('userDetails:passwordValid')),
        revokerefreshtokens: z.boolean()
    });

export const AccountDetailsPassword: FunctionComponent<{
    onSuccess: () => void;
}> = ({ onSuccess }) => {
    const { t } = useTranslation(['userDetails', 'common', 'inputFields']);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <ToggleRow
            header={
                <Box style={{ width: 'auto' }}>
                    <Text as="span" wrap size="medium">
                        {t('userDetails:passwordSimple')}:
                    </Text>
                    &nbsp;
                    <Text as="span" wrap size="medium">
                        **********
                    </Text>
                </Box>
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            button={
                <DeprecatedButton
                    variant="underline"
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    {isOpen ? t('common:close') : t('common:changeDetails')}
                </DeprecatedButton>
            }
            justifyContent="spaceBetween"
        >
            <Box marginTop="small">
                <SchemeForm
                    fields={{
                        oldpassword: {
                            type: 'password',
                            placeholder: t(
                                'inputFields:placeholderCurrentPassword'
                            ),
                            label: t('inputFields:labelCurrentPassword'),
                            autoComplete: 'password'
                        },
                        password: {
                            type: 'createPassword',
                            label: t('inputFields:labelNewPassword'),
                            placeholder: t(
                                'inputFields:placeholderNewPassword'
                            ),
                            helperText: t('inputFields:infoCreatePassword'),
                            autoComplete: 'new-password'
                        },
                        revokerefreshtokens: {
                            type: 'checkbox',
                            label: t('userDetails:revokeRefreshTokens')
                        }
                    }}
                    method="put"
                    path="/api/users/password"
                    onSuccess={() => {
                        setIsOpen(false);
                        onSuccess();
                    }}
                    formOptions={{
                        resetOnSuccess: true,
                        resolver: zodResolver(makeChangePasswordSchema(t)),
                        defaultValues: {
                            oldpassword: '',
                            password: '',
                            revokerefreshtokens: false
                        }
                    }}
                    i18n={{ namespaces: ['userDetailErrors'] }}
                />
            </Box>
        </ToggleRow>
    );
};
