import React, { type FC } from 'react';
import classNames from 'classnames';
import { SlimNavbar } from 'bb/app/nav';
import { type PageLayoutProps } from 'bb/page/types';
import { type Color } from 'bb/style/types';
import css from './layoutWithMenu.module.scss';

type LayoutWithoutMenuProps = PageLayoutProps & {
    // eslint-disable-next-line react/no-unused-prop-types
    bgColor?: Color;
    children?: React.ReactNode;
};

export const LayoutWithoutMenu: FC<LayoutWithoutMenuProps> = ({ children }) => (
    <div className={classNames(css.base, css.noMenu)}>
        <SlimNavbar />
        {children}
    </div>
);
