import React, { type FunctionComponent, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { observer } from 'bb/app/stores';
import { ToggleRow } from 'bb/common/ToggleRow';
import { SchemeForm } from 'bb/forms/SchemeForm';
import { useTranslation, Text } from 'bb/i18n';
import { nameValidation } from 'bb/registration/components/SignupForm/helpers';
import { Box, DeprecatedButton } from 'bb/ui';
import { AvatarIcon } from 'bb/ui/Icons/svgs';
import { useUser } from '../hooks';

export const AccountDetailsName: FunctionComponent<{
    onSuccess: () => void;
}> = observer(({ onSuccess }) => {
    const { t } = useTranslation(['inputFields', 'userDetails', 'common']);

    const [isOpen, setIsOpen] = useState(false);
    const { user } = useUser();

    return (
        <ToggleRow
            header={
                <Box style={{ width: 'auto' }}>
                    <Text as="span" wrap size="medium">
                        {t('userDetails:name')}:
                    </Text>
                    &nbsp;
                    <Text as="span" wrap size="medium">
                        {user?.displayname}
                    </Text>
                </Box>
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            button={
                <Box style={{ width: 'auto' }}>
                    <DeprecatedButton
                        variant="underline"
                        onClick={() => setIsOpen((prev) => !prev)}
                    >
                        {isOpen ? t('common:close') : t('common:changeDetails')}
                    </DeprecatedButton>
                </Box>
            }
            justifyContent="spaceBetween"
        >
            {user && (
                <Box marginTop="small">
                    <SchemeForm
                        method="put"
                        path="/api/users"
                        fields={{
                            firstname: {
                                placeholder: t(
                                    'inputFields:placeholderFirstName'
                                ),
                                label: t('inputFields:labelFirstName'),
                                autoComplete: 'given-name',
                                type: 'text',
                                startAdornment: <AvatarIcon size="small" />,
                                maxLength: 200
                            },
                            lastname: {
                                placeholder: t(
                                    'inputFields:placeholderLastName'
                                ),
                                label: t('inputFields:labelLastName'),
                                autoComplete: 'family-name',
                                type: 'text',
                                startAdornment: <AvatarIcon size="small" />,
                                maxLength: 200
                            },
                            iskid: {
                                type: 'hidden',
                                hidden: true
                            }
                        }}
                        formOptions={{
                            defaultValues: {
                                firstname: user.firstname,
                                lastname: user.lastname,
                                iskid: user.iskid
                            },
                            resolver: zodResolver(
                                z.object({
                                    firstname: nameValidation(t),
                                    lastname: nameValidation(t)
                                })
                            )
                        }}
                        onSuccess={() => {
                            setIsOpen(false);
                            onSuccess();
                        }}
                        i18n={{
                            namespaces: ['profileErrors']
                        }}
                    />
                </Box>
            )}
        </ToggleRow>
    );
});
