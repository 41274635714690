import React, { type FunctionComponent, useState } from 'react';
import { Link } from 'bb/app/nav';
import { routes } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { ToggleRow } from 'bb/common/ToggleRow';
import { useTranslation, Text } from 'bb/i18n';
import { Box, DeprecatedButton } from 'bb/ui';

export const AccountDetailsSetPassword: FunctionComponent = observer(() => {
    const { t } = useTranslation(['userDetails', 'common', 'login']);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <ToggleRow
            header={
                <Box style={{ width: 'auto' }}>
                    <Text as="span" wrap size="medium">
                        {t('userDetails:passwordSimple')}:
                    </Text>
                    &nbsp;
                    <Text as="span" wrap size="medium" />
                </Box>
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            button={
                <DeprecatedButton
                    variant="underline"
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    {isOpen ? t('common:close') : t('common:addDetails')}
                </DeprecatedButton>
            }
            justifyContent="spaceBetween"
        >
            <Text
                as="p"
                size="medium"
                marginBottom="extraSmall"
                marginTop="extraSmall"
                t="userDetails:createPasswordThroughForgotPassword"
            />

            <Link route={routes.forgottenPassword}>
                {t('login:forgottenPasswordLink')}
            </Link>
        </ToggleRow>
    );
});
