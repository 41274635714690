/* eslint-disable no-nested-ternary */
import { type HTMLInputTypeAttribute } from 'react';
import { HookFormCheckbox } from 'bb/ui/Form/Checkbox';
import {
    HookFormInput,
    HookFormEmailInput,
    HookFormPasswordInput,
    HookFormCreatePasswordInput
} from 'bb/ui/Form/Input';
import {
    type FieldConfig,
    type TextFieldType,
    type CheckboxType,
    type EmailType,
    type PasswordType,
    type CreatePasswordType,
    type FieldType
} from './SchemeForm.types';

export const TEXT_FIELD_TYPES = ['text', 'hidden'] as const;
export const CHECKBOX_TYPES = ['checkbox'] as const;
export const PASSWORD_TYPES = ['password'] as const;
export const CREATE_PASSWORD_TYPES = ['createPassword'] as const;
export const EMAIL_TYPES = ['email'] as const;

export const ALL_TYPES = [
    ...TEXT_FIELD_TYPES,
    ...CHECKBOX_TYPES,
    ...PASSWORD_TYPES,
    ...CREATE_PASSWORD_TYPES,
    ...EMAIL_TYPES
] as const;

/**
 * We map the our custom type to the HTML input type attribute
 * so it is rendered correctly in the DOM.
 */
export const TYPE_TO_HTML_TYPE_MAP: Partial<
    Record<FieldType, HTMLInputTypeAttribute>
> = {
    createPassword: 'password'
};

/**
 * React hook form reactivity breaks with dynamic imports so
 * we have to (unforunately) use actual imports.
 */
export const _HookFormInput = HookFormInput;
_HookFormInput.displayName = 'HookFormInput';

export const _HookFormEmailInput = HookFormEmailInput;
_HookFormEmailInput.displayName = 'HookFormEmailInput';

export const _HookFormPasswordInput = HookFormPasswordInput;
_HookFormPasswordInput.displayName = 'HookFormPasswordInput';

export const _HookFormCreatePasswordInput = HookFormCreatePasswordInput;
_HookFormCreatePasswordInput.displayName = 'HookFormCreatePasswordInput';

export const _HookFormCheckbox = HookFormCheckbox;
_HookFormCheckbox.displayName = 'Checkbox';

export const getFieldComponent = (type: FieldConfig['type']) =>
    TEXT_FIELD_TYPES.includes(type as TextFieldType)
        ? _HookFormInput
        : CHECKBOX_TYPES.includes(type as CheckboxType)
          ? _HookFormCheckbox
          : EMAIL_TYPES.includes(type as EmailType)
            ? _HookFormEmailInput
            : PASSWORD_TYPES.includes(type as PasswordType)
              ? _HookFormPasswordInput
              : CREATE_PASSWORD_TYPES.includes(type as CreatePasswordType)
                ? _HookFormCreatePasswordInput
                : null;

export const isHookFormInput = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ForwardRefExoticComponent<any>
): component is
    | typeof _HookFormInput
    | typeof _HookFormEmailInput
    | typeof _HookFormPasswordInput
    | typeof _HookFormCreatePasswordInput =>
    Boolean(
        component.displayName &&
            [
                'HookFormInput',
                'HookFormEmailInput',
                'HookFormPasswordInput',
                'HookFormCreatePasswordInput'
            ].includes(component.displayName)
    );

export const isHookFormCheckbox = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ForwardRefExoticComponent<any>
): component is typeof _HookFormCheckbox =>
    component.displayName === 'HookFormCheckbox';

export const makeFieldEntries = (fields: Record<string, FieldConfig>) =>
    (
        Object.entries(fields) as [
            string,
            FieldConfig & React.ComponentProps<'input'>
        ][]
    ).sort((a, b) => (a[1].order ?? 0) - (b[1].order ?? 0));

/**
 * Turns a nested fields object into a a flat dot notation
 * object.
 */
export const flattenFieldObject = (
    obj: Record<string, unknown>,
    parentKey?: string
): Record<string, FieldConfig> =>
    (Object.entries(obj) as [string, FieldConfig][]).reduce<
        Record<string, FieldConfig>
    >((acc, [key, value]) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (value !== null && typeof value === 'object') {
            if ((value as FieldConfig).type) {
                return { ...acc, [fullKey]: value };
            }

            return {
                ...acc,
                ...flattenFieldObject(value, fullKey)
            };
        }

        return { ...acc, [fullKey]: value };
    }, {});
