import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { NavigateLoggedOutUser } from 'bb/account/components/NavigateLoggedOutUser';
import { useRouter } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { MobileBackgroundLayout } from 'bb/page/MobileBackgroundLayout';
import { type PageLayout, type PageLayoutProps } from 'bb/page/types';
import { TrackingContext } from 'bb/tracker/contexts';
import { Row, Box, Stack } from 'bb/ui';
import css from './accountLayout.module.scss';
import { useAccountStore } from './hooks';

const context = { context: 'account' };

export const AccountLayout: PageLayout<
    PageLayoutProps & {
        variant?: 'noBg' /**
         * If passed this element will be rendered directly
         * under the nav bar.
         */;
        navWidget?: React.ReactNode;
    }
> = observer(
    ({ children, classNames, renderOnlyChildren, variant, navWidget }) => {
        const { router } = useRouter();
        const { isLoggedIn } = useAccountStore();
        const [hasBeenLoggedIn, setHasBeenLoggedIn] = useState(isLoggedIn);
        useEffect(() => {
            if (isLoggedIn) setHasBeenLoggedIn(true);
        }, [isLoggedIn]);

        /**
         * If the user has been logged in we dont want to add query param `redirectTo` because the user has logged out.
         * Only add query params if the user has not yet been logged in.
         */
        const query = hasBeenLoggedIn
            ? undefined
            : { redirectTo: router.pathname };

        return (
            <MobileBackgroundLayout>
                {navWidget}
                <TrackingContext.Provider value={context}>
                    <NavigateLoggedOutUser query={query} />
                    {(() => {
                        if (renderOnlyChildren) return children;

                        return classNames ? (
                            <div className={classnames(classNames)}>
                                {children}
                            </div>
                        ) : (
                            <Row justifyContent="center">
                                <Box
                                    className={classnames(
                                        css.content,
                                        variant && css[variant]
                                    )}
                                    padding="extraSmall"
                                >
                                    <Stack>{children}</Stack>
                                </Box>
                            </Row>
                        );
                    })()}
                </TrackingContext.Provider>
            </MobileBackgroundLayout>
        );
    }
);
