// @ts-strict-ignore
import { type FunctionComponent, useCallback } from 'react';
import { useAccountStore } from 'bb/account/hooks/useAccountStore';
import { useRouter } from 'bb/app/router/useRouter';
import { useShouldRedirect } from 'bb/app/router/useShouldRedirect';
import { observer } from 'bb/app/stores';

type NavigateLoggedOutUserProps = {
    query?: Record<string, unknown>;
};

export const NavigateLoggedOutUser: FunctionComponent<NavigateLoggedOutUserProps> =
    observer(({ query = {} }) => {
        const accountStore = useAccountStore();
        const { routes } = useRouter();

        const shouldRedirectPredicate = useCallback(
            () => accountStore.isLoggedIn === false,
            [accountStore.isLoggedIn]
        );
        useShouldRedirect(shouldRedirectPredicate, routes.login, query);

        return null;
    });
