import React, { type FC } from 'react';
import { useHALGet } from 'bb/api/browser';
import { AppDownloadBadges } from 'bb/common/AppDownloadBadges';
import { getHalEmbeddedData } from 'bb/common/hal';
import { type HalLink } from 'bb/common/types';
import { useTranslation, Text } from 'bb/i18n';
import { Flex, List, ListItem, Row, Spinner } from 'bb/ui';
import { TextButton } from 'bb/ui/Button';
import { DeviceListItem } from './DeviceListItem';
import { type Devices } from './types';

type DeviceListProps = {
    link: HalLink;
    empty: boolean;
};

export const DeviceList: FC<DeviceListProps> = ({ link, empty }) => {
    const { t } = useTranslation(['myAccount', 'common', 'accountActivated']);
    const { data: result, error, isWaiting, mutate } = useHALGet<Devices>(link);
    const items = getHalEmbeddedData(result)?.devices;

    return (
        <Spinner nested size="small" show={isWaiting}>
            {error && (
                <Text color="secondary-red" t="myAccount:failedToFetchDevices">
                    <TextButton
                        as="a"
                        underline
                        href={`mailto:${t('common:supportEmail')}`}
                    >
                        {t('common:supportEmail')}
                    </TextButton>
                </Text>
            )}

            {(items?.length === 0 || empty) && (
                <>
                    <Flex justifyContent="center">
                        <Text center marginTop="medium">
                            {t('myAccount:noDevices')}
                        </Text>
                    </Flex>
                    <Row
                        marginTop="small"
                        alignItems="center"
                        justifyContent="center"
                        flexWrap="wrap"
                    >
                        <AppDownloadBadges
                            appStoreLink={t('common:appStoreLink')}
                            color="black"
                        />
                        <AppDownloadBadges
                            playStoreLink={t('common:playStoreLink')}
                            color="black"
                        />
                    </Row>
                </>
            )}
            <List alignItems="flexStart">
                {!empty &&
                    items?.map((device, i) => (
                        <ListItem
                            fluid
                            paddingBottom={
                                i !== items.length - 1 ? 'extraSmall' : 'none'
                            }
                            key={device.id}
                        >
                            <DeviceListItem
                                device={device}
                                refetchCallback={mutate}
                            />
                        </ListItem>
                    ))}
            </List>
        </Spinner>
    );
};
