import { isDef } from 'bb/utils';
import type { UserDetails, RegistrationPayload, Consent } from './types';

export const CONSENT_PREFIX = 'bbconsent';

export const createRegistrationPayload = (
    details: UserDetails,
    consentsResponse?: Consent[]
) =>
    Object.entries(details).reduce(
        (acc, [key, value]) => {
            if (key === 'termsAndConditions') return acc;
            if (key.includes(CONSENT_PREFIX)) {
                const name = key.replace(CONSENT_PREFIX, '');
                const ix = acc.consents?.findIndex((c) => c.name === name);

                if (isDef(ix) && ix > -1 && acc.consents) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    acc.consents[ix]!.hasGivenConsent = Boolean(value);
                }

                return acc;
            }

            return {
                ...acc,
                [key]: value
            };
        },
        (consentsResponse
            ? {
                  consents: consentsResponse.map((c) => ({
                      name: c.key,
                      hasGivenConsent: c.defaultValue
                  }))
              }
            : {}) as RegistrationPayload
    );
