// @ts-strict-ignore
import React from 'react';
import {
    ProfileBlueIcon,
    ProfileBlueKidsIcon,
    ProfileGrayIcon,
    ProfileGreenIcon,
    ProfileGreenKidsIcon,
    ProfileInactiveIcon,
    ProfileInactiveKidsIcon,
    ProfilePurpleIcon,
    ProfilePurpleKidsIcon,
    ProfileRedIcon,
    ProfileRedKidsIcon,
    ProfileYellowIcon,
    ProfileYellowKidsIcon
} from 'bb/ui/Icons';

const iconMap = {
    // TODO Extract to util, used elseware aswell
    avatar_orange: <ProfileYellowIcon size="medium" />,
    avatar_purple: <ProfilePurpleIcon size="medium" />,
    avatar_red: <ProfileRedIcon size="medium" />,
    avatar_green: <ProfileGreenIcon size="medium" />,
    avatar_blue: <ProfileBlueIcon size="medium" />,
    avatar_gray: <ProfileGrayIcon size="medium" />,
    avatar_not_activated: <ProfileInactiveIcon size="medium" />,

    // kids
    avatar_orange_kid: <ProfileYellowKidsIcon size="medium" />,
    avatar_purple_kid: <ProfilePurpleKidsIcon size="medium" />,
    avatar_red_kid: <ProfileRedKidsIcon size="medium" />,
    avatar_green_kid: <ProfileGreenKidsIcon size="medium" />,
    avatar_blue_kid: <ProfileBlueKidsIcon size="medium" />,
    avatar_gray_kid: <ProfileInactiveKidsIcon size="medium" />
};

type ProfileIconProps = {
    id: string;
};

export const ProfileIcon: React.FC<ProfileIconProps> = ({ id }) =>
    iconMap[id] || null;
