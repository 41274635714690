import { z } from 'zod';
import { VALID_NAME_REGEX } from 'bb/forms/validation';
import { type TFunction } from 'bb/i18n';
import { type ConsentKey, type Consent } from 'bb/registration/types';
import { CONSENT_PREFIX } from 'bb/registration/utils';

export const nameValidation = (t: TFunction<'userDetails'>) =>
    z.string().trim().regex(VALID_NAME_REGEX, t('userDetails:nameRequired'));

export const getConsentKey = (consent: Consent['key']): ConsentKey =>
    `${CONSENT_PREFIX}${consent}`;

export const makeSignupInitialData = (consents: Consent[]) =>
    consents.reduce<Record<ConsentKey, boolean>>(
        (obj, consent) => ({
            ...obj,
            [getConsentKey(consent.key)]: consent.defaultValue
        }),
        {}
    );

export const createSignupFormSchema = (
    t: TFunction<'userDetails' | 'consents'>
) =>
    z
        .object({
            firstName: nameValidation(t),
            lastName: nameValidation(t),
            email: z.string().email({ message: t('userDetails:emailValid') }),
            password: z
                .string()
                .min(6, t('userDetails:passwordValid'))
                .max(99, t('userDetails:passwordValid')),
            termsAndConditions: z.literal(true, {
                errorMap: () => ({
                    message: t('consents:consentIsRequired')
                })
            })
        })
        .passthrough();

export const trimOnBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>
) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = event.target.value.trim();
};
