import React, { type FunctionComponent } from 'react';
import { PENDING } from 'bb/api/browser/symbols';
import { useRouter } from 'bb/app/router';
import { useTranslation, Text } from 'bb/i18n';
import { List, ListItem, Spinner } from 'bb/ui';
import { TextButton } from 'bb/ui/Button';
import css from './devices.module.scss';
import { type Device } from './types';
import { useRemoveDevice } from './useRemoveDevice';

type DeviceListItemProps = {
    device: Device;
    refetchCallback: () => void;
};

export const DeviceListItem: FunctionComponent<DeviceListItemProps> = ({
    device,
    refetchCallback
}: DeviceListItemProps) => {
    const { t } = useTranslation(['common', 'myAccount']);

    const { execute, status, error } = useRemoveDevice();
    const isLoading = status === PENDING;

    const { router } = useRouter();
    const locale = router?.locale;

    const deleteDevice = () =>
        device?.id && execute(device.id).then(refetchCallback);

    return (
        <Spinner ghost size="small" nested show={isLoading}>
            <List
                direction="row"
                alignItems="flexStart"
                justifyContent="spaceBetween"
                flexWrap="noWrap"
            >
                <ListItem>
                    <Text className={css.deviceName} bold>
                        {device?.name.trim() || t('myAccount:unknownDevice')}
                    </Text>
                    <Text variant="body2">
                        {t('myAccount:deviceLastUsed', {
                            date: new Date(device?.lastused).toLocaleString(
                                locale
                            )
                        })}
                    </Text>
                    {error && (
                        <Text
                            color="secondary-red"
                            variant="body2"
                            t="myAccount:failedToDelete"
                        >
                            <TextButton
                                as="a"
                                underline
                                href={`mailto:${t('common:supportEmail')}`}
                            >
                                {t('common:supportEmail')}
                            </TextButton>
                        </Text>
                    )}
                </ListItem>
                <ListItem>
                    <div className={css.deleteDeviceButton}>
                        <TextButton underline onClick={deleteDevice}>
                            {t('myAccount:deviceDelete')}
                        </TextButton>
                    </div>
                </ListItem>
            </List>
        </Spinner>
    );
};
