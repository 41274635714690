/* eslint-disable react/jsx-indent */
import React from 'react';
import classNames from 'classnames';
import { type FieldValues } from 'react-hook-form';
import { useMutation } from 'bb/api/browser';
import { type FilteredPathKey, type MutationMethods } from 'bb/api/types';
import { type Namespace, useFormErrorMessage, useTranslation } from 'bb/i18n';
import { DeprecatedButton, FormMessage, Gap, useForm } from 'bb/ui';
import {
    ALL_TYPES,
    TYPE_TO_HTML_TYPE_MAP,
    flattenFieldObject,
    getFieldComponent,
    isHookFormInput,
    makeFieldEntries
} from './helpers';
import css from './schemeForm.module.scss';
import {
    type CheckboxType,
    type TextFieldType,
    type GetFieldProps,
    type SchemeFormApi,
    type SchemeFormProps,
    type FieldType
} from './SchemeForm.types';

const CONTROLLED_FIELDS: FieldType[] = ['createPassword'];

/**
 * The \<SchemeForm \/> component is an abstraction built upon
 * SWR and react-hook-form. Its purpose is to render a form
 * and its fields in a type safe manner.
 *
 * See schemeForm.stories.tsx for example(s) of how to implement.
 */

// props cannot be inferred properly due to the complex nature
// of the SchemeFormApi interface.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SchemeForm: SchemeFormApi = (props) => {
    const {
        method,
        path,
        formOptions = {},
        fields = {},
        i18n,
        fluid = true,
        className,
        ...swrProps
    } = props as unknown as SchemeFormProps<
        MutationMethods,
        FilteredPathKey<MutationMethods>,
        FieldValues,
        Response
    >;

    const { namespaces = [] } = i18n ?? {};
    const allNamespaces = Array.from(
        new Set(['common', ...namespaces])
    ) as Namespace[];
    const { t } = useTranslation(allNamespaces);
    const { mode = 'onChange', ...restFormOptions } = formOptions;
    const { submit: submitCopy = t('common:save') } = i18n ?? {};

    const { handleSubmit, register, formState, control } = useForm<FieldValues>(
        {
            mode,
            ...restFormOptions
        }
    );

    const { trigger, error: submitError } = useMutation<
        FieldValues,
        Response,
        object,
        'generic'
    >(method, path, swrProps);

    const { isSubmitting, isValid, errors } = formState;

    const formError = useFormErrorMessage(
        submitError,
        allNamespaces,
        'common:unknown_error'
    );

    const fieldsEntries = makeFieldEntries(flattenFieldObject(fields));

    return (
        <form
            className={classNames(fluid && css.fluid, className)}
            onSubmit={handleSubmit((data) => trigger(data))}
        >
            <Gap spacing={4}>
                {fieldsEntries.map(
                    ([
                        fieldName,
                        { order: _order, type, disabled, ...fieldData }
                    ]) => {
                        const Component = getFieldComponent(type);

                        if (!Component) {
                            // eslint-disable-next-line no-console
                            console.warn(
                                `<SchemeForm />: a valid field type must be specified. Supported types are: ${ALL_TYPES.join(
                                    ', '
                                )}.`
                            );

                            return null;
                        }

                        const commonProps = {
                            error: errors[fieldName],
                            disabled: disabled || isSubmitting,
                            ...register(fieldName),
                            ...(CONTROLLED_FIELDS.includes(type)
                                ? { control }
                                : {})
                        };

                        if (isHookFormInput(Component)) {
                            const htmlInputType =
                                TYPE_TO_HTML_TYPE_MAP[type] ?? type;

                            return (
                                <Component
                                    key={fieldName}
                                    type={htmlInputType}
                                    {...(fieldData as GetFieldProps<TextFieldType>)}
                                    {...commonProps}
                                />
                            );
                        }

                        return (
                            <Component
                                key={fieldName}
                                {...commonProps}
                                {...(fieldData as GetFieldProps<CheckboxType>)}
                            />
                        );
                    }
                )}

                <Gap spacing={2}>
                    {!isSubmitting && (
                        <FormMessage
                            status={{
                                message: formError
                            }}
                        />
                    )}

                    <DeprecatedButton
                        type="submit"
                        variant="primary"
                        isLoading={isSubmitting}
                        disabled={!isValid || isSubmitting}
                    >
                        {submitCopy}
                    </DeprecatedButton>
                </Gap>
            </Gap>
        </form>
    );
};
