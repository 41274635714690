import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { type ErrorResponse } from 'bb/api/browser/types';
import { Link } from 'bb/app/nav';
import { useRouter } from 'bb/app/router';
import {
    type TFunction,
    Text,
    useFormErrorMessage,
    useTranslation
} from 'bb/i18n';
import css from 'bb/style/common.module.scss';
import {
    Row,
    FormMessage,
    SubmitButton,
    Spinner,
    Gap,
    type UseHookFormComponentProps,
    useForm,
    HookFormEmailInput
} from 'bb/ui';

const makeChangeEmailSchema = (t: TFunction<'userDetails'>) =>
    z.object({
        email: z.string().email(t('userDetails:emailValid'))
    });

export type ChangeEmailSchema = z.infer<
    ReturnType<typeof makeChangeEmailSchema>
>;

export type ChangeEmailFormProps =
    UseHookFormComponentProps<ChangeEmailSchema> & {
        warning?: ErrorResponse;
    };

export const ChangeEmailForm = ({
    onSubmit,
    onError,
    isLoading = false,
    error,
    warning,
    ...useFormProps
}: ChangeEmailFormProps) => {
    const { t } = useTranslation(['userDetails', 'common', 'inputFields']);
    const { routes } = useRouter();
    const formError = useFormErrorMessage(
        error,
        ['userDetailErrors', 'common'],
        'common:unknown_error',
        {
            link: <Link route={routes.login}>{t('common:login')}</Link>
        }
    );

    const { handleSubmit, formState, register } = useForm<ChangeEmailSchema>({
        ...useFormProps,
        resolver: zodResolver(makeChangeEmailSchema(t)),
        mode: 'onSubmit'
    });

    const { isValid, isSubmitting, isDirty, errors } = formState;

    const formWarning = useFormErrorMessage(warning, ['userDetailErrors']);

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} className={css.fluid}>
            <Gap spacing={4}>
                <Spinner ghost nested show={isLoading}>
                    <Gap spacing={4}>
                        <Text t="userDetails:changeEmailText" />

                        <HookFormEmailInput
                            {...register('email')}
                            placeholder={t('inputFields:placeholderNewEmail')}
                            label={t('inputFields:labelNewEmail')}
                            autoComplete="email"
                            fluid
                            error={errors.email}
                        />
                    </Gap>
                </Spinner>

                {!(isSubmitting || isLoading) && (
                    <FormMessage
                        status={{
                            message: formError || formWarning
                        }}
                    />
                )}
                <Row justifyContent="center">
                    <SubmitButton
                        text={t('userDetails:changeEmailButton')}
                        isSubmitting={isSubmitting || isLoading}
                        fluid
                        isValid={(isDirty && isValid) || (warning && isValid)}
                        type="primary"
                    />
                </Row>
            </Gap>
        </form>
    );
};
