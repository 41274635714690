import * as React from 'react';
import { useHALGet } from 'bb/api/browser';
import { type HalLink } from 'bb/common/types';
import { useFeature } from 'bb/config/features';
import { useTranslation, Text, Header } from 'bb/i18n';
import { type ProductResponse } from 'bb/subscription/types';
import { Card, Col, Spinner, Stack } from 'bb/ui';
import { type ProfileProductResponse } from '../types';
import {
    ProfilePricesPerSubscription,
    ProfilePricesPerSubscriptionV2
} from './ProfilePricesPerSubscription';
import css from './profiles.module.scss';

type ProfilesInfoProps = {
    link: HalLink;
};

export const ProfilesInfo: React.FC<ProfilesInfoProps> = ({ link }) => {
    const profileCampaignsEnabled = useFeature('profile-campaigns');
    useTranslation(['myAccount']);
    const { data, isWaiting } = useHALGet<ProfileProductResponse[]>(link);

    return (
        <Col className={css.base}>
            <Spinner nested show={isWaiting} size="small">
                <Card withBorder padding="extraSmall">
                    <Header
                        type="secondary"
                        as="h2"
                        size="small"
                        marginBottom="extraSmall"
                        t="myAccount:profilesInformationHeader"
                    />
                    <Stack>
                        <Text
                            as="div"
                            size="medium"
                            t="myAccount:profilesInformationText"
                        >
                            {data &&
                                (profileCampaignsEnabled ? (
                                    <ProfilePricesPerSubscriptionV2
                                        products={data}
                                    />
                                ) : (
                                    <ProfilePricesPerSubscription
                                        products={
                                            data as unknown as ProductResponse[]
                                        }
                                    />
                                ))}
                        </Text>
                    </Stack>
                </Card>
            </Spinner>
        </Col>
    );
};
