import React from 'react';
import { LayoutWithMenu } from 'bb/page/LayoutWithMenu';
import {
    MobileBackground,
    type MobileBackgroundProps
} from 'bb/page/MobileBackground';
import { type PageLayout } from 'bb/page/types';
import { LayoutWithoutMenu } from './LayoutWithoutMenu';

export type MobileBackgroundLayoutProps = MobileBackgroundProps & {
    withMenu?: boolean;
};

export const MobileBackgroundLayout: PageLayout<
    MobileBackgroundLayoutProps
> = ({
    children,
    justifyContent = 'center',
    withMenu = true,
    ...restProps
}) => {
    const Layout = withMenu ? LayoutWithMenu : LayoutWithoutMenu;
    return (
        <Layout bgColor="transparent">
            <MobileBackground {...restProps} justifyContent={justifyContent}>
                {children}
            </MobileBackground>
        </Layout>
    );
};
