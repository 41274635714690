import React from 'react';
import classNames from 'classnames';
import { type PageLayout } from 'bb/page/types';
import { Flex, type FlexProps } from 'bb/ui/Flex';
import css from './mobileBackground.module.scss';

export type MobileBackgroundProps = {
    /**
     * If true, the content will be centered in the page with
     * height and width set to `auto` in desktop.
     *
     * @default true
     */
    automaticSizeInDesktop?: boolean;
} & FlexProps;

export const MobileBackground: PageLayout<MobileBackgroundProps> = ({
    children,
    className,
    justifyContent = 'center',
    automaticSizeInDesktop = true,
    ...restProps
}) => (
    <Flex
        justifyContent={justifyContent}
        className={classNames(
            css.base,
            automaticSizeInDesktop && css.automaticSizeInDesktop,
            className
        )}
        {...restProps}
    >
        {children}
    </Flex>
);
