import React, { type FunctionComponent, useState } from 'react';
import { type ErrorResponse } from 'bb/api/browser/types';
import { observer } from 'bb/app/stores';
import { ToggleRow } from 'bb/common/ToggleRow';
import {
    ChangeEmailForm,
    type ChangeEmailFormProps
} from 'bb/forms/ChangeEmailForm';
import { useTranslation, Text } from 'bb/i18n';
import { Box, DeprecatedButton } from 'bb/ui';
import { toast } from 'bb/utils';
import { useAccountStore } from '../hooks/useAccountStore';
import { useUpdateEmail } from '../hooks/useUpdateEmail';

export const AccountDetailsEmail: FunctionComponent<{
    onSuccess: () => void;
}> = observer(({ onSuccess }) => {
    const { t } = useTranslation(['userDetails', 'accountActions', 'common']);
    const accountStore = useAccountStore();

    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState<ErrorResponse>();

    const { isMutating, trigger } = useUpdateEmail({
        throwOnError: true
    });

    const onSubmit: ChangeEmailFormProps['onSubmit'] = async (
        formDetails,
        form
    ) => {
        try {
            setError(undefined);
            await trigger({
                newemail: formDetails.email
            });

            form.reset();
            toast.warning(
                t('accountActions:warningConfirmEmailChange', {
                    newUserEmail: formDetails.email
                }),
                {
                    disableLimitRows: true
                }
            );

            setIsOpen(false);
            onSuccess();
        } catch (e) {
            setError(e as ErrorResponse);
        }
    };

    return (
        <ToggleRow
            header={
                <Box style={{ width: 'auto' }}>
                    <Text as="span" wrap size="medium">
                        {t('userDetails:email')}:
                    </Text>
                    &nbsp;
                    <Text as="span" wrap size="medium">
                        {accountStore.user?.email}
                    </Text>
                </Box>
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            button={
                <Box style={{ width: 'auto' }}>
                    <DeprecatedButton
                        variant="underline"
                        onClick={() => setIsOpen((prev) => !prev)}
                    >
                        {isOpen ? t('common:close') : t('common:changeDetails')}
                    </DeprecatedButton>{' '}
                </Box>
            }
            justifyContent="spaceBetween"
        >
            <Box paddingTop="small">
                <ChangeEmailForm
                    onSubmit={onSubmit}
                    isLoading={isMutating}
                    defaultValues={{ email: '' }}
                    error={error}
                />
            </Box>
        </ToggleRow>
    );
});
